import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SolicitorDashboard from 'components/dashboard';
import AdminMySettings from 'components/dashboard/my-settings';
import AdminListing from 'components/dashboard/admin-lists';
import CreateNewAdmin from 'components/dashboard/admin-lists/add-new-admin';
// import EditAdminForm from 'components/dashboard/admin-lists/edit-admin/EditAdminForm';
import EditAdmin from 'components/dashboard/admin-lists/edit-admin';
import SolicitorListing from 'components/dashboard/solicitor-lists';
import ViewSolicitor from 'components/dashboard/solicitor-lists/view-solicitor';
import EmailTemplateListing from 'components/email-management';
import EditEmailTemplate from 'components/email-management/edit-email-template';
import PortfolioAcceptanceListing from 'components/dashboard/portfolio-acceptance';

// widget routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <SolicitorDashboard />
        },
        {
            path: '/my-settings',
            element: <AdminMySettings />
        },

        {
            path: '/admins',
            element: <AdminListing />
        },
        {
            path: '/admins/add_admin',
            element: <CreateNewAdmin />
        },
        {
            path: `/admins/edit_admin/:id`,
            element: <EditAdmin adminData="" setAdminData="" />
        },
        {
            path: '/clients/solicitors',
            element: <SolicitorListing filterInput="" />
        },
        {
            path: '/clients/solicitors/:id',
            element: <ViewSolicitor />
        },
        {
            path: '/email-template-list',
            element: <EmailTemplateListing />
        },
        {
            path: `/email-template-list/:id/edit`,
            element: <EditEmailTemplate />
        },
        {
            path: `/portfolioAcceptance`,
            element: <PortfolioAcceptanceListing filterInput="" />
        }
    ]
};

export default MainRoutes;
