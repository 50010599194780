import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AdminResetPassword = Loadable(lazy(() => import('components/authentication/reset-password/index')));
const AdminVerification = Loadable(lazy(() => import('components/authentication/verification/index')));
const AdminLogin = Loadable(lazy(() => import('components/authentication/login/index')));
const AdminForgotPassword = Loadable(lazy(() => import('components/authentication/forgot-password/index')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AdminLogin />
        },
        {
            path: '/forgot',
            element: <AdminForgotPassword />
        },
        {
            path: '/verification',
            element: <AdminVerification />
        },
        {
            path: '/reset',
            element: <AdminResetPassword />
        }
    ]
};

export default LoginRoutes;
