import { gql } from '@apollo/client';

export const LIST_ALL_ADMINS = gql`
    query ListOfAllAdmins($query: SearchAdminInput!) {
        listOfAllAdmins(query: $query) {
            data {
                _id
                firstName
                lastName
                email
                phoneNumber
                role
                createdAt
                updatedAt
                status
            }
            pagination {
                total
                currentPage
                hasNextPage
            }
        }
    }
`;

export const LIST_ALL_SOLICITORS = gql`
    query ListOfAllSolicitors($query: SearchSolicitorInput!) {
        listOfAllSolicitors(query: $query) {
            data {
                _id
                createdAt
                updatedAt
                fullName
                firmName
                role
                firmAddress {
                    displayAddress
                    street
                    suburb
                }
                subscriptionType
                email
                contactNumber
                description
                features
                status
            }
            pagination {
                total
                currentPage
                hasNextPage
            }
        }
    }
`;

export const GET_ADMIN_DETAILS = gql`
    query GetAdminDetails($id: String!) {
        getAdminDetails(id: $id) {
            _id
            createdAt
            firstName
            lastName
            email
            role
            status
            phoneNumber
            hasChangedPassword
        }
    }
`;

export const GET_SOLICITOR_DETAILS = gql`
    query GetSolicitorDetailsForAdmin($id: String!) {
        getSolicitorDetailsForAdmin(id: $id) {
            solicitor {
                fullName
                contactNumber
                subscriptionType
                email
                status
            }
            numberOfEnquiries
            numberOfComprehensiveWills
            solicitorIncomings
            sentimentIncomings
            listOfComprehensiveWills {
                data {
                    amount
                    createdAt
                    amount
                    _id
                    benefactor
                }
                pagination {
                    total
                    currentPage
                    hasNextPage
                }
            }
        }
    }
`;

export const LIST_ALL_EMAIL_TEMPLATE = gql`
    query ListOfAllEmailTemplate($query: SearchEmailTemplateInput!) {
        listOfAllEmailTemplate(query: $query) {
            data {
                _id
                createdAt
                updatedAt
                title
                slug
                subject
                content
            }
            pagination {
                total
                currentPage
                hasNextPage
            }
        }
    }
`;
export const GET_EMAIL_TEMPLATE_DETAILS = gql`
    query GetEmailTemplateDetails($id: String!) {
        getEmailTemplateDetails(id: $id) {
            _id
            createdAt
            updatedAt
            title
            slug
            subject
            content
        }
    }
`;
export const LIST_ALL_SHARED_PORTFOLIOS = gql`
    query listAllSharedPortfolios($query: PortfolioSearchInput!) {
        listAllSharedPortfolios(query: $query) {
            data {
                _id
                beneficiaryFullName
                benefactorFullName
                deathCertificate
                appliedAt
                permissionApprovalStatus
            }
            pagination {
                total
                currentPage
                hasNextPage
            }
        }
    }
`;
