import { Button, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import AdminList from './AdminList';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SubCard from 'ui-component/cards/SubCard';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import navigation from 'menu-items';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const AdminListing = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { user } = useAuth();

    const createNewAdmin = () => navigate('/admins/add_admin');

    return (
        <MainCard content={false} className="no-border">
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="flex-end" spacing={8}>
                    <Grid item xs={2} sm={2} sx={{ left: '10px', position: 'relative' }}>
                        <Typography variant="h3">Admins</Typography>
                    </Grid>
                    {user?.role === 'SUPER_ADMIN' && (
                        <Grid item xs={3} sm={3} sx={{ m: -1, float: 'right' }}>
                            <Button onClick={createNewAdmin} variant="contained" sx={{ float: 'right' }} className="info-button">
                                Create New Admin
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            <AdminList />
        </MainCard>
    );
};

export default AdminListing;
