import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, gql, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

// TODO:redirect after refreshToken expires
const getNewAccessToken = () => {
    const GET_TOKEN_QUERY = gql`
        mutation ($body: RefreshTokenInput!) {
            getNewAccessToken(body: $body) {
                accesstoken
                accessTokenExpiresIn
            }
        }
    `;

    const refreshtoken = window.localStorage.getItem('refreshtoken');

    if (refreshtoken) {
        return client
            .mutate({
                mutation: GET_TOKEN_QUERY,
                variables: {
                    body: {
                        refreshtoken
                    }
                }
            })
            .then((response: any) => {
                const { accesstoken } = response.data?.getNewAccessToken;
                localStorage.removeItem('accesstoken');
                localStorage.setItem('accesstoken', accesstoken);
                return accesstoken;
            })
            .catch((error) => {
                localStorage.removeItem('accesstoken');
                localStorage.removeItem('refreshtoken');
                console.log('refresh token failed');
                console.log('refresh failed...');
                // throw error;
            });
    }

    return false;
};

const authMiddleware = new ApolloLink((operation, forward) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
    }

    return forward(operation);
});

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            switch (err.extensions.code) {
                case 'UNAUTHENTICATED': {
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: getNewAccessToken()
                        }
                    });

                    return forward(operation);
                }
            }
        }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_KEY
});

const retryLink = new RetryLink({
    attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error
    },
    delay: {
        initial: 300,
        jitter: true,
        max: 5000
    }
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authMiddleware, errorLink, retryLink, httpLink]),
    name: 'WillandEstate',
    version: '1.1'
});

client.defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all'
    }
};

export default client;
