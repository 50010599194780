import MoreVertIcon from '@mui/icons-material/MoreVert';

const headCells = [
    {
        id: 'fullName',
        numeric: false,
        label: 'Solicitor',
        align: 'left',
        type: 'silicitorName'
    },
    {
        id: 'contactNumber',
        numeric: true,
        label: 'Main Contact',
        align: 'left',
        type: 'field'
    },
    {
        id: 'email',
        numeric: true,
        label: 'Email',
        align: 'left',
        type: 'field'
    },
    {
        id: 'firmAddress',
        numeric: true,
        label: 'Location',
        align: 'left',
        type: 'location'
    },
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date Created',
        align: 'left',
        type: 'Date'
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left',
        type: 'Status'
    },

    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'left',
        type: 'ActionSolicitor'
    }
];

const actionTable = [
    {
        name: 'View Solicitor',
        variant: 'outlined',
        color: 'primary'
    },
    {
        name: 'Delete Solicitor',
        variant: 'outlined',
        color: 'primary'
    }
];

const filterList = [
    { label: 'Premium', checked: false, field: 'subscriptionType' },
    { label: 'Standard', checked: false, field: 'subscriptionType' }
];

const SolicitorListingConstants = {
    headCells,
    actionTable,
    filterList
};

export default SolicitorListingConstants;
