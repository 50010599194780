// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography } from '@mui/material';

// project imports
import ReactQuillDemo from './wysiwug-components/ReactQuill';

import { gridSpacing } from 'store/constant';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AnyIfEmpty } from 'react-redux';

// ==============================|| PLUGIN - EDITORS ||============================== //

const WysiwygEditor = ({ value, onChange, setContent }: { value: any; onChange: any; setContent: any }) => {
    const theme = useTheme();

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Stack
                    spacing={gridSpacing}
                    sx={{
                        '& .quill': {
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                            borderColor: 'black',
                            borderRadius: '12px',
                            '& .ql-toolbar': {
                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                borderTopLeftRadius: '12px',
                                borderTopRightRadius: '12px'
                            },
                            '& .ql-container': {
                                borderColor:
                                    theme.palette.mode === 'dark' ? `${theme.palette.dark.light + 20} !important` : 'primary.light',
                                borderBottomLeftRadius: '12px',
                                borderBottomRightRadius: '12px',
                                '& .ql-editor': {
                                    minHeight: 350
                                }
                            }
                        }
                    }}
                >
                    <ReactQuill
                        value={value}
                        onChange={(e) => {
                            setContent(e);
                            onChange(e);
                        }}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default WysiwygEditor;
