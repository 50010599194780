import { FormattedMessage } from 'react-intl';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';

const icons = {
    PersonOutlineOutlinedIcon,
    PublicTwoToneIcon
};

const clients = {
    id: 'clients-menu',
    // title: <FormattedMessage id="Clients" />,
    icon: icons.PersonOutlineOutlinedIcon,
    type: 'group',
    breadcrumbs: false,
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.PublicTwoToneIcon,
            breadcrumbs: true
        },
        {
            id: 'admins',
            title: <FormattedMessage id="Admins" />,
            type: 'item',
            url: '/admins',
            icon: icons.PersonOutlineOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'clients',
            title: <FormattedMessage id="Clients" />,
            type: 'item',
            url: '/clients/solicitors',
            icon: icons.PersonOutlineOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'portfolioAcceptance',
            title: <FormattedMessage id="Portfolio Acceptance" />,
            type: 'item',
            url: '/portfolioAcceptance',
            icon: icons.PersonOutlineOutlinedIcon,
            breadcrumbs: true
        }
    ]
};

export default clients;
