import { Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button, Slide, Grid } from '@mui/material';
import React from 'react';

const DisableModal = ({
    title,
    onOkClicked,
    content,
    okButtonName,
    closeButtonName,
    open,
    onShowOrHideDisableModal
}: {
    title: string;
    onOkClicked: () => Promise<void>;
    content: string;
    okButtonName?: any;
    closeButtonName?: any;
    open: boolean;
    onShowOrHideDisableModal: (flag: any) => void;
}) => (
    <Dialog
        open={open}
        // TransitionComponent={DisableTransitionEffect}
        keepMounted
        onClose={() => onShowOrHideDisableModal(false)}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-title">
            <Grid container justifyContent="flex-center">
                {title}
            </Grid>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <strong>{content}</strong>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onOkClicked} color="secondary" variant="outlined">
                {okButtonName || 'Yes'}
            </Button>
            <Button onClick={() => onShowOrHideDisableModal(false)} color="error" variant="outlined">
                {closeButtonName || 'Cancel'}
            </Button>
        </DialogActions>
    </Dialog>
);

export default DisableModal;
