import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import SimpleDataTable from 'components/common/SimpleDataTable';
import { DELETE_SOLICITOR, ENABLE_SOLICITOR } from 'queries/mutation';
import { GET_SOLICITOR_DETAILS } from 'queries/queries';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import SolicitorCardDetails from './CardDetails';
import SolicitorDetailsPage from './SolicitorDetails';
import WillConstants from './will-details/WillConstants';

interface SolicitorDetails {
    fullName: string;
    contactNumber: string;
    subscriptionType: string;
    email: string;
    status: string;
}
interface ComprehensiveWillData {
    amount: string;
    client: string;
    createdAt: Date;
}

interface ComprehensiveWillList {
    data: ComprehensiveWillData;
    pagination: any;
}
interface SolicitorViewDetails {
    solicitor: SolicitorDetails;
    numberOfEnquiries: string;
    numberOfComprehensiveWills: string;
    solicitorIncomings: string;
    sentimentIncomings: string;
    listOfComprehensiveWills: ComprehensiveWillList;
}

const ViewSolicitor = () => {
    const params = useParams();

    const [pageGet, setPageGet] = useState(1);
    const [checked, setChecked] = useState(false);

    const [deleteSolicitorData] = useMutation(DELETE_SOLICITOR);
    const [enableSolicitorData] = useMutation(ENABLE_SOLICITOR);

    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [solicitorDetails, setSolicitoDetails] = useState<SolicitorViewDetails>();

    const getSolicitorDetails = useQuery(GET_SOLICITOR_DETAILS, {
        variables: { id: params?.id, query: { page: pageGet, perPage: pageSize || null } }
    });

    const onTicked = () => {
        setChecked(!checked);
        if (!checked) {
            try {
                const deleteData = deleteSolicitorData({
                    variables: { id: params?.id }
                });
                window.location.reload();
            } catch (error) {
                console.log('ERROR');
            }
        } else {
            const enableData = enableSolicitorData({
                variables: { id: params?.id }
            });

            window.location.reload();
        }
    };

    useEffect(() => {}, [deleteSolicitorData, enableSolicitorData]);

    useEffect(() => {
        if (getSolicitorDetails?.data) {
            const getSolicitorData = getSolicitorDetails?.data?.getSolicitorDetailsForAdmin;
            setSolicitoDetails({
                solicitor: getSolicitorData?.solicitor || '',
                numberOfEnquiries: getSolicitorData?.numberOfEnquiries || '',
                numberOfComprehensiveWills: getSolicitorData?.numberOfComprehensiveWills || '',
                solicitorIncomings: getSolicitorData?.solicitorIncomings || '',
                sentimentIncomings: getSolicitorData?.sentimentIncomings || '',
                listOfComprehensiveWills: getSolicitorData?.listOfComprehensiveWills || ''
            });
            if (getSolicitorData?.solicitor?.status === 'DISABLED') {
                setChecked(true);
            }
            setRowData(getSolicitorData?.listOfComprehensiveWills?.data);
            setTotalCount(getSolicitorData?.listOfComprehensiveWills?.pagination.total);
        }
        if (getSolicitorDetails?.error) {
            setTotalCount(0);
            setRowData([]);
            setErrorMessage('No Data Found');
        }
    }, [getSolicitorDetails.data]);

    return (
        <MainCard title="Account">
            {solicitorDetails ? (
                <>
                    <Grid item xs={12} className="solicitor-details-grid">
                        <Grid item xs={10}>
                            <SolicitorDetailsPage solicitor={solicitorDetails.solicitor} />
                            {solicitorDetails.solicitor.status !== 'INACTIVE' && (
                                <FormControlLabel
                                    className="disable-user"
                                    onChange={onTicked}
                                    control={<Checkbox defaultChecked={checked} />}
                                    label="Disable user?"
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} className="solicitor-details-card-div">
                            <SolicitorCardDetails cardDetails={solicitorDetails} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" justifyContent="center" className="wills-list-container">
                        <Grid container className="wills-list-heading">
                            <Typography variant="h3">Comprehensive Wills</Typography>
                        </Grid>
                        <Grid item xs={12} className="wills-list">
                            <SimpleDataTable
                                error={errorMessage}
                                getPageSize={setPageSize}
                                getPageCount={setPageGet}
                                rowData={rowData}
                                tableActions={WillConstants.actionTable}
                                headerCells={WillConstants.headCells}
                                totalItems={totalCount}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Loader />
            )}
        </MainCard>
    );
};

export default ViewSolicitor;
