// import React from 'react';
import { useEffect, useState } from 'react';

import { Card, CardContent, Dialog, DialogTitle, Divider, Grid, IconButton, List } from '@mui/material';

import { useTheme } from '@mui/styles';

import CreateSolicitorForm from './CreateSolicitorForm';
import CloseIcon from '@mui/icons-material/Close';

import MainCard from 'ui-component/cards/MainCard';

const CreateNewSolicitor = ({ setSolicitorState }: { setSolicitorState: any }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    return (
        <div>
            <Dialog
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '360px', // Set your width here
                            minHeight: '400px'
                        }
                    }
                }}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <DialogTitle id="dialog-box-heading">
                    Invite New Solicitor
                    <IconButton
                        // aria-label="close"
                        onClick={() => {
                            setSolicitorState(false);
                        }}
                        sx={{
                            position: 'absolute',
                            right: '18px',
                            top: '20px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Card className="solicitor-invite-form">
                    <CardContent sx={{ pt: 0 }}>
                        <List>
                            <CreateSolicitorForm />
                        </List>
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    );
};

export default CreateNewSolicitor;
