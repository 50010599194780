import { CardContent, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';

import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import EmailTemplateList from './EmailManagementList';

const EmailTemplateListing = () => {
    const theme = useTheme();
    const [searchInput, setSearchInput] = useState('');
    const [sortInput, setSortInput] = useState('');

    const handleFilterChange = (e: any) => {
        setSortInput(e.target.value);
        console.log(sortInput, 'THIS IS SORT INPUT');
    };

    return (
        <MainCard content={false} className="no-border">
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="flex-end" spacing={8}>
                    <Grid item xs={8} sm={8}>
                        <Typography variant="h3">Emails</Typography>
                    </Grid>
                    <Grid className="parent-email-template-element">
                        <Grid item>
                            <FormControl>
                                <TextField
                                    className="child-search-button"
                                    InputProps={{
                                        startAdornment: <SearchIcon />
                                    }}
                                    label="Search"
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <InputLabel>Sort By</InputLabel>
                                <Select className="child-sort-button" label="Sort By" name="sortBy" onChange={(e) => handleFilterChange(e)}>
                                    <MenuItem value="title">Title</MenuItem>
                                    <MenuItem value="updatedAt">Updated At</MenuItem>
                                    <MenuItem value="createdAt">Created At</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <EmailTemplateList searchInput={searchInput} sortInput={sortInput || 'title'} />
        </MainCard>
    );
};

export default EmailTemplateListing;
