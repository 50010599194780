import { gql } from '@apollo/client';

export const REGISTER_SOLICITOR_MUTATION = gql`
    mutation RegisterSolicitor($registerBody: RegisterSolicitor!) {
        registerSolicitor(registerBody: $registerBody) {
            solicitor {
                _id
                contactNumber
                email
                firmAddress
                firmName
                fullName
                subscriptionType
            }
            token {
                accessToken
                refreshToken
            }
        }
    }
`;

export const UPDATE_SOLICITOR = gql`
    mutation UpdateSolicitor($updateBody: SolicitorInput!) {
        updateSolicitor(updateBody: $updateBody) {
            _id
            contactNumber
            createdAt
            email
            firmAddress
            firmName
            fullName
            role
            subscriptionType
            updatedAt
        }
    }
`;

export const CREATE_SOLICITOR = gql`
    mutation CreateSolicitor($body: SolicitorInput!) {
        createSolicitor(body: $body) {
            solicitor {
                _id
                contactNumber
                email
                firmAddress
                firmName
                fullName
                subscriptionType
            }
            token {
                accessToken
                refreshToken
            }
        }
    }
`;

export const INVITE_SOLICITOR = gql`
    mutation CreateSolicitor($body: SolicitorInviteInput!) {
        createSolicitor(body: $body) {
            solicitor {
                _id
                contactNumber
                email
                firmAddress {
                    displayAddress
                }
                firmName
                fullName
                subscriptionType
            }
            token {
                accessToken
                refreshToken
            }
        }
    }
`;

export const LOGIN_ADMIN = gql`
    mutation LoginAdmin($body: AdminLoginInput!) {
        loginAdmin(body: $body) {
            admin {
                _id
                email
                firstName
                lastName
                status
                phoneNumber
                role
            }
            token {
                accessToken
                refreshToken
            }
        }
    }
`;

export const CREATE_ADMIN = gql`
    mutation CreateAdmin($body: CreateAdminInput!) {
        createAdmin(body: $body) {
            _id
            createdAt
            updatedAt
            firstName
            lastName
            role
            email
            status
            phoneNumber
            deletedAt
        }
    }
`;

export const UPDATE_ADMIN = gql`
    mutation UpdateAdmin($body: UpdateAdminInput!) {
        updateAdmin(body: $body) {
            firstName
            lastName
            phoneNumber
            email
            createdAt
            updatedAt
            role
            status
            deletedAt
            hasChangedPassword
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation AdminResetPassword($body: ResetPasswordInput!) {
        adminResetPassword(body: $body) {
            message
        }
    }
`;
export const VERIFY_FORGOT_PASSWORD_TOKEN = gql`
    mutation resetPasswordTokenVerification($body: VerifyResetPasswordTokenInput!) {
        adminResetTokenVerification(body: $body) {
            tempResetToken
        }
    }
`;
export const CHANGE_PASSWORD = gql`
    mutation resetPasswordTokenVerificationUpdate($body: UpdateResetPasswordInput!) {
        adminResetPasswordUpdate(body: $body) {
            success
            message
        }
    }
`;

export const SETTINGS_CHANGE_PASSWORD = gql`
    mutation AdminChangePassword($body: ChangePasswordInput!) {
        adminChangePassword(body: $body) {
            success
            message
        }
    }
`;

export const DELETE_ADMIN = gql`
    mutation DeleteAdmin($id: String!) {
        deleteAdmin(id: $id) {
            success
            message
        }
    }
`;

export const DELETE_SOLICITOR = gql`
    mutation DeleteSolicitor($id: String!) {
        deleteSolicitor(id: $id) {
            success
            message
        }
    }
`;

export const ENABLE_SOLICITOR = gql`
    mutation EnableSolicitor($id: String!) {
        enableSolicitor(id: $id) {
            success
            message
        }
    }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
    mutation UpdateEmailTemplate($body: UpdateEmailTemplateInput!) {
        updateEmailTemplate(body: $body) {
            _id
            createdAt
            updatedAt
            title
            slug
            subject
            content
        }
    }
`;
