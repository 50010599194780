import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useEffect, useState } from 'react';

const SolicitorDashboard = () => {
    const theme = useTheme();

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Grid>
            <Typography>THIS IS DASHBOARD</Typography>
        </Grid>
    );
};

export default SolicitorDashboard;
