import { Chip } from '@mui/material';

const Status = ({ status }: { status: any }) => {
    const getChipColor = () => {
        if (status === 'Active') {
            return '#B9F6CA';
        }
        if (status === 'Inactive') {
            return '#FBE9E7';
        }
        if (status === 'Disabled') {
            return '#FBE9E7';
        }
        if (status === 'Approved') {
            return '#B9F6CA';
        }
        if (status === 'Pending') {
            return '#FFF8E1';
        }
        if (status === 'Rejected') {
            return '#FBE9E7';
        }
        return 'green';
    };
    const getTextColor = () => {
        if (status === 'Active') {
            return '#00C853';
        }
        if (status === 'Inactive') {
            return '#D84315';
        }
        if (status === 'Disabled') {
            return '#D84315';
        }
        if (status === 'Approved') {
            return '#00C853';
        }
        if (status === 'Pending') {
            return '#FFC107';
        }
        if (status === 'Rejected') {
            return '#D84315';
        }
        return 'white';
    };

    const getLabel = () => {
        console.log(status);
        if (status === 'Active') {
            return 'Active';
        }
        if (status === 'Inactive') {
            return 'Inactive';
        }
        if (status === 'Disabled') {
            return 'Disabled';
        }
        if (status === 'Approved') {
            return 'Accepted';
        }
        if (status === 'Pending') {
            return 'Pending';
        }
        if (status === 'Rejected') {
            return 'Rejected';
        }
        return 'N/A';
    };

    return (
        <Chip
            variant="filled"
            sx={{ background: getChipColor(), color: getTextColor(), width: '100px', height: '24px', left: '1125px', top: '927px' }}
            size="small"
            label={getLabel()}
        />
    );
};
export default Status;
