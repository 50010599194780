import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import useScriptRef from 'hooks/useScriptRef';

import { useTheme } from '@mui/styles';

import EditAdminForm from './EditAdminForm';
import MainCard from 'ui-component/cards/MainCard';
import DanglingHelper from 'components/common/DanglingHelper';
import { GET_ADMIN_DETAILS } from 'queries/queries';
import { UPDATE_ADMIN } from 'queries/mutation';
import Loader from 'ui-component/Loader';

interface AdminDataType {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    status: string;
}

interface EditPage {
    adminData: string;
    setAdminData: string;
}

const EditAdmin: React.FC<EditPage> = () => {
    const theme = useTheme();
    const params = useParams();

    const scriptedRef = useScriptRef();
    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState(null);
    const [adminData, setAdminData] = useState<AdminDataType>();

    const getAdminDetails = useQuery(GET_ADMIN_DETAILS, {
        variables: { id: params?.id }
    });
    const [updateAdminData, { data, error }] = useMutation(UPDATE_ADMIN);

    const ApiCall = async (values: any) => {
        await updateAdminData({
            variables: {
                body: {
                    _id: params?.id,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    email: values.email,
                    status: values.status
                }
            }
        });
    };

    useEffect(() => {
        if (data) {
            navigate('/admins');
        }
    }, [data]);

    useEffect(() => {
        if (getAdminDetails?.data) {
            const getAdminData = getAdminDetails?.data?.getAdminDetails;
            setAdminData({
                id: DanglingHelper(getAdminData, '_id') || '',
                firstName: getAdminData?.firstName || '',
                lastName: getAdminData?.lastName || '',
                phoneNumber: getAdminData?.phoneNumber || '',
                email: getAdminData?.email || '',
                status: getAdminData?.status || ''
            });
        }
    }, [getAdminDetails.data]);

    return (
        <MainCard title="Edit Admin">
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    {adminData ? <EditAdminForm adminData={adminData} setAdminData={setAdminData} onUpdate={ApiCall} /> : <Loader />}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default EditAdmin;
