import React from 'react';

import { Button, Grid, Menu, MenuItem, Pagination } from '@mui/material';
import { gridSpacing } from 'store/constant';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const TablePagination = ({
    handlePaginationChange,
    count = 0,
    pageSize = 0,
    handleChangePageSize,
    pageNumber = 0
}: {
    handlePaginationChange?: any;
    count?: number;
    pageSize?: number;
    handleChangePageSize?: any;
    pageNumber?: number;
}) => {
    const totalPage = Math.ceil(Number(count) / pageSize);

    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeSizeOfThePage = (size: any) => {
        handleChangePageSize(size);
        setAnchorEl(null);
    };
    return (
        <Grid item xs={12} sx={{ p: 3 }}>
            <Grid container justifyContent="space-between" spacing={gridSpacing}>
                <Grid item>
                    <Pagination
                        page={pageNumber === 0 ? pageNumber + 1 : pageNumber}
                        onChange={(e, page) => handlePaginationChange(page)}
                        shape="rounded"
                        count={totalPage}
                    />
                </Grid>
                <Grid item>
                    <Button size="large" color="secondary" endIcon={<ExpandMoreRoundedIcon />} onClick={handleClick}>
                        {pageSize} Rows
                    </Button>
                    <Menu
                        id="menu-user-list-style1"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem onClick={() => handleChangeSizeOfThePage(10)}> 10 Rows</MenuItem>
                        <MenuItem onClick={() => handleChangeSizeOfThePage(20)}> 20 Rows</MenuItem>
                        <MenuItem onClick={() => handleChangeSizeOfThePage(30)}> 30 Rows </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TablePagination;
