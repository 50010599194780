import { useState } from 'react';
import { Box, Button, Grid, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

const ActionButtonIconOnly = ({
    handleClickItem,
    itemId,
    item,
    newOptions = [],
    status
}: {
    handleClickItem: (a: any, b: any, c: any) => Promise<void>;
    itemId: any;
    item: any;
    newOptions: any[];
    status?: string;
}) => {
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const getItemId = (row: any, key: any) => row[key];

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container spacing={2} sx={{ left: '60px' }}>
            <>
                <Box sx={{ border: 1, borderColor: '#2563EB', borderRadius: 1, marginTop: 2, padding: '5px 0px ' }}>
                    <Button onClick={handleClick} className="action-button">
                        <MoreVertIcon fontSize="small" />
                    </Button>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClick={handleClose}
                    variant="menu"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    sx={{ boxShadow: 0 }}
                >
                    {newOptions.map((allOpt, index) =>
                        !allOpt?.isHide ? (
                            <>
                                {allOpt.name === 'Edit Admin' && (
                                    <Link
                                        to={`/admins/edit_admin/${getItemId(item, '_id')}`}
                                        style={{ textDecoration: 'none', color: 'grey' }}
                                    >
                                        <MenuItem onClick={() => handleClickItem(allOpt.name, itemId, item)}>{allOpt.name} </MenuItem>
                                    </Link>
                                )}
                                {allOpt.name === 'View Solicitor' && (
                                    <Link
                                        to={`/clients/solicitors/${getItemId(item, '_id')}`}
                                        style={{ textDecoration: 'none', color: 'grey' }}
                                    >
                                        <MenuItem onClick={() => handleClickItem(allOpt.name, itemId, item)}>{allOpt.name} </MenuItem>
                                    </Link>
                                )}
                                {allOpt.name !== 'Edit Admin' && allOpt.name !== 'View Solicitor' && status === 'ACTIVE' && (
                                    <MenuItem onClick={() => handleClickItem(allOpt.name, itemId, item)}>{allOpt.name} </MenuItem>
                                )}
                                {allOpt.name === 'Edit Template' && (
                                    <MenuItem onClick={() => handleClickItem(allOpt.name, itemId, item)}>{allOpt.name} </MenuItem>
                                )}
                                {allOpt.name === 'View Application' && status !== 'APPROVED' && (
                                    <Link
                                        to={`/clients/solicitors/${getItemId(item, '_id')}`}
                                        style={{ textDecoration: 'none', color: 'grey' }}
                                    >
                                        <MenuItem onClick={() => handleClickItem(allOpt.name, itemId, item)}>{allOpt.name} </MenuItem>
                                    </Link>
                                )}
                            </>
                        ) : null
                    )}
                </Menu>
            </>
        </Grid>
    );
};
export default ActionButtonIconOnly;
