import { Autocomplete, CardContent, FormControl, Grid, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import MainCard from 'ui-component/cards/MainCard';

import { useState } from 'react';

import PortfolioList from './PortfolioList';

interface ListingPage {
    filterInput: string;
}

const PortfolioAcceptanceListing: React.FC<ListingPage | null> = () => {
    const [filtersInput, setFiltersInput] = useState<string>(' ');
    const [searchInput, setSearchInput] = useState('');

    const handleInputChange = (event: React.SyntheticEvent, newValue: string) => {
        const inputValues = newValue === 'PENDING' ? 'REQUESTED' : newValue;
        console.log('inputvalues', newValue);
        setFiltersInput(inputValues);
    };

    const PERMISSION_STATUS = [
        { label: 'APPROVED', id: 1 },
        { label: 'REJECTED', id: 2 },
        { label: 'PENDING', id: 3 }
    ];

    return (
        <MainCard content={false} className="no-border">
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="flex-end" spacing={4}>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: '20px' }}>
                        <Typography variant="h3">Portfolio Acceptance</Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            options={PERMISSION_STATUS}
                            inputValue={`${filtersInput === 'REQUESTED' ? 'PENDING' : filtersInput}`}
                            onInputChange={handleInputChange}
                            renderInput={(params) => <TextField {...params} label="Status" />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                InputProps={{
                                    startAdornment: <SearchIcon />
                                }}
                                label="Search"
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>

            <PortfolioList searchInput={searchInput || ''} filterInput={filtersInput || null} />
        </MainCard>
    );
};

export default PortfolioAcceptanceListing;
