import { Card, CardContent, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from 'react';
import { TabsProps } from 'types';
import { gridSpacing } from 'store/constant';
import useConfig from 'hooks/useConfig';
import AdminProfile from './AdminProfile';
import AdminResetPassword from './ResetPassword';
import DashboardCard from 'components/ui-components/DashboardCard';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FormattedMessage } from 'react-intl';

function TabPanel({ children, value, index, ...other }: TabsProps) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const settingsOption = [
    {
        label: 'User Profile',
        icon: <LockOutlinedIcon />,
        caption: 'Profile Settings'
    },
    {
        label: 'Reset Password',
        icon: <LockOutlinedIcon />,
        caption: 'Create a new password'
    }
];

const AdminMySettings = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();

    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <DashboardCard title="My Settings" content={false}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} lg={4}>
                                <CardContent>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        orientation="vertical"
                                        variant="scrollable"
                                        sx={{
                                            '& .MuiTabs-flexContainer': {
                                                borderBottom: 'none'
                                            },
                                            '& button': {
                                                color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                                                minHeight: 'auto',
                                                minWidth: '100%',
                                                py: 1.5,
                                                px: 2,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                borderRadius: `${borderRadius}px`
                                            },
                                            '& button.Mui-selected': {
                                                color: theme.palette.primary.main,
                                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                                            },
                                            '& button > svg': {
                                                marginBottom: '0px !important',
                                                marginRight: 1.25,
                                                marginTop: 1.25,
                                                height: 20,
                                                width: 20
                                            },
                                            '& button > div > span': {
                                                display: 'block'
                                            },
                                            '& > div > span': {
                                                display: 'none'
                                            }
                                        }}
                                    >
                                        {settingsOption.map((tab, index) => (
                                            <Tab
                                                key={index}
                                                icon={tab.icon}
                                                label={
                                                    <Grid container direction="column">
                                                        <Typography variant="subtitle1" color="inherit">
                                                            {tab.label}
                                                        </Typography>
                                                        <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                                            {tab.caption}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                {...a11yProps(index)}
                                            />
                                        ))}
                                    </Tabs>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <CardContent
                                    sx={{
                                        borderLeft: '1px solid',
                                        borderColor:
                                            theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                                        height: '100%'
                                    }}
                                >
                                    <TabPanel value={value} index={0}>
                                        <AdminProfile />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <AdminResetPassword />
                                    </TabPanel>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </DashboardCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AdminMySettings;
