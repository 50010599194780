import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useTheme } from '@mui/styles';
import Status from 'components/common/Status';
import SimpleDataTable from 'components/common/SimpleDataTable';
import { LIST_ALL_ADMINS } from 'queries/queries';
import { DELETE_ADMIN } from 'queries/mutation';
import AdminListingConstants from './AdminListConstants';
import DanglingHelper from 'components/common/DanglingHelper';
import DisableModal from 'components/common/DeleteComponent';
import { useNavigate } from 'react-router-dom';

const AdminList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [pageGet, setPageGet] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowData, setRowData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const [selectedItem, setSelectedItem] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [deleteAdminData] = useMutation(DELETE_ADMIN);

    // const editAdmin = () => navigate(`/admins/edit_admin/${DanglingHelper(selectedItem, '_id')}`);

    const onShowOrHideDisableModal = (flag: any) => {
        if (!flag) {
            setSelectedItem(null);
        }
        setShowDeleteModal(flag);
    };

    const { data, error, loading, refetch } = useQuery(LIST_ALL_ADMINS, {
        variables: { query: { page: pageGet, perPage: pageSize || null } }
    });
    const deleteRow = (item: any) => {
        onShowOrHideDisableModal(true);
        setSelectedItem(item);
    };
    useEffect(() => {
        if (data) {
            setRowData(data?.listOfAllAdmins?.data);
            setTotalCount(data?.listOfAllAdmins?.pagination.total);
            setDeleteErrorMessage('');
        }

        if (error) {
            setDeleteErrorMessage('No Data Found');

            setErrorMessage('No Data Found');
            setRowData([]);
            setTotalCount(0);
        }
        if (loading) {
            setErrorMessage('');
            setRowData([]);
            setTotalCount(0);
        }
    }, [data, error, loading]);

    const onDeleteApiCall = async () => {
        try {
            await deleteAdminData({
                variables: { id: DanglingHelper(selectedItem, '_id') || '' }
            });

            setRowData(rowData.filter((row) => row !== selectedItem));
            onShowOrHideDisableModal(false);
        } catch (e) {
            setErrorMessage('Admin cannot be deleted');
            onShowOrHideDisableModal(false);
        }
    };

    useEffect(() => {
        refetch();
        setOnChangeSearch(false);
    }, [refetch]);

    const handleActionClickAction = (name: any, itemId: any, item: any) => {
        switch (name) {
            case 'Edit':
                // return editAdmin();
                return console.log('{hello}');

            case 'Delete Admin':
                return deleteRow(item);
            default:
                return console.log('{hello}');
        }
    };

    const onPreviewRow = (id: any, datas: any) => {
        console.log('id', id);
        console.log('data', datas);
    };

    return (
        <>
            <SimpleDataTable
                error={errorMessage}
                loading={loading}
                getPageSize={setPageSize}
                getPageCount={setPageGet}
                rowData={rowData}
                handleClickAction={handleActionClickAction}
                onPreviewRow={onPreviewRow}
                StatusComponent={Status}
                tableActions={AdminListingConstants.actionTable}
                headerCells={AdminListingConstants.headCells}
                totalItems={totalCount}
                changePage={onChangeSearch}
            />
            <DisableModal
                onShowOrHideDisableModal={onShowOrHideDisableModal}
                content="Are you sure you want to delete Admin?"
                onOkClicked={onDeleteApiCall}
                open={showDeleteModal}
                title="Delete Admin"
            />
            {/* {deleteErrorMessage && (
                <SnackbarMessage message={deleteErrorMessage} open code="500" onClose={() => setDeleteErrorMessage('')} />
            )} */}
        </>
    );
};

export default AdminList;
