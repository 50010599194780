import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';

import { UPDATE_EMAIL_TEMPLATE } from 'queries/mutation';
import { GET_EMAIL_TEMPLATE_DETAILS } from 'queries/queries';

import DanglingHelper from 'components/common/DanglingHelper';
import MainCard from 'ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import EditEmailTemplateForm from './EditEmailTemplateForm';
import Loader from 'ui-component/Loader';

interface EmailTemplateData {
    id: string;
    title: string;
    content: string;
    subject: string;
    createdAt: string;
    updatedAt: string;
}

const EditEmailTemplate = () => {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();

    const [emailTemplateData, setEmailTemplateData] = useState<EmailTemplateData>();

    const getEmailTemplateDetails = useQuery(GET_EMAIL_TEMPLATE_DETAILS, {
        variables: { id: params?.id }
    });

    const [updateEmailTemplateData] = useMutation(UPDATE_EMAIL_TEMPLATE);

    const ApiCall = async (values: any) => {
        await updateEmailTemplateData({
            variables: {
                body: {
                    _id: params?.id,
                    title: values.title,
                    subject: values.subject,
                    content: values.content
                }
            }
        });
        navigate('/email-template-list');
    };

    useEffect(() => {
        if (getEmailTemplateDetails?.data) {
            const getEmailTemplateData = getEmailTemplateDetails?.data?.getEmailTemplateDetails;
            setEmailTemplateData({
                id: DanglingHelper(getEmailTemplateData, '_id') || '',
                title: getEmailTemplateData?.title || '',
                content: getEmailTemplateData?.content || '',
                subject: getEmailTemplateData?.subject || '',
                createdAt: getEmailTemplateData?.createdAt || '',
                updatedAt: getEmailTemplateData?.updatedAt || ''
            });
            console.log(getEmailTemplateData, 'thIS IS EMAIL TEMPLATE DATA');
        }
    }, [getEmailTemplateDetails.data]);

    return (
        <MainCard>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    {emailTemplateData ? (
                        <EditEmailTemplateForm
                            emailTemplateData={emailTemplateData}
                            setEmailTemplateData={setEmailTemplateData}
                            onUpdate={ApiCall}
                        />
                    ) : (
                        <Loader />
                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default EditEmailTemplate;
