import { Grid } from '@mui/material';

import { useTheme } from '@mui/styles';

import CreateAdminForm from './CreateAdminForm';
import MainCard from 'ui-component/cards/MainCard';
import SolicitorCardDetails from 'components/dashboard/solicitor-lists/view-solicitor/CardDetails';
import SolicitorWillDetails from 'components/dashboard/solicitor-lists/view-solicitor/SolicitorWillDetails';

const CreateNewAdmin = () => {
    const theme = useTheme();

    return (
        <MainCard title="Create new admin">
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <CreateAdminForm />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default CreateNewAdmin;
