const headCells = [
    {
        id: 'id',
        numeric: false,
        label: '#',
        align: 'left',
        type: 'field'
    },
    {
        id: 'title',
        numeric: false,
        label: 'Template name',
        align: 'left',
        type: 'field'
    },
    {
        id: 'updatedAt',
        numeric: true,
        label: 'Date Updated',
        align: 'left',
        type: 'Date'
    },
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date Created',
        align: 'left',
        type: 'Date'
    },

    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'left',
        type: 'ActionEmailTemplate'
    }
];

const actionTable = [
    {
        name: 'Edit Template',
        variant: 'outlined',
        color: 'primary'
    }
];

const filterList = [
    { label: 'Title', checked: false, field: 'title' },
    { label: 'Updated At', checked: false, field: 'updatedAt' },

    { label: 'Created At', checked: false, field: 'createdAt' }
];

const EmailTemplateListingConstants = {
    headCells,
    actionTable,
    filterList
};

export default EmailTemplateListingConstants;
