import { Box, Button, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/styles';
import WysiwygEditor from 'components/Wysiwug/WysiwugEditor';
import { Field, Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useState } from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';

import * as Yup from 'yup';
import EmailTemplateOnPreview from './EmailTemplateOnPreview';

const EditEmailTemplateForm = ({
    emailTemplateData,
    setEmailTemplateData,
    onUpdate
}: {
    emailTemplateData: any;
    setEmailTemplateData: any;
    onUpdate: any;
}) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    const [content, setContent] = useState<string>(emailTemplateData?.content);
    const [open, setOpen] = useState<boolean>(false);

    const openPreviewBox = () => {
        setOpen(true);
        console.log('ASDADSDSSDSAD');
    };

    return (
        <Formik
            initialValues={{
                title: emailTemplateData?.title,
                content: emailTemplateData?.content,
                subject: emailTemplateData?.subject,

                submit: null
            }}
            validationSchema={Yup.object().shape({
                title: Yup.string().required('Title is required'),
                content: Yup.string().required('Content is required'),
                subject: Yup.string().required('Subject is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const response = await onUpdate({ title: values.title, subject: values.subject, content });

                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err: any) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid>
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.title && errors.title)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="email-template-text-field"
                                        id="outlined-adornment-title-reset"
                                        InputProps={{ disableUnderline: true }}
                                        value={values.title}
                                        name="title"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Template Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.title && errors.title && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.title}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.subject && errors.subject)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="email-template-text-field"
                                        id="outlined-adornment-subject-reset"
                                        InputProps={{ disableUnderline: true }}
                                        value={values.subject}
                                        name="subject"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Subject Line"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.subject && errors.subject && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.subject}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className="editor-container">
                            <WysiwygEditor value={content} onChange={handleChange} setContent={setContent} />
                        </Grid>
                    </Grid>
                    <Grid className="flex-center-bottom">
                        <Grid>
                            <Box
                                sx={{
                                    mt: 1
                                }}
                            >
                                <AnimateButton>
                                    <Button size="large" variant="outlined" className="preview-button" onClick={openPreviewBox}>
                                        Preview
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </Grid>
                        <Grid>
                            <Box
                                sx={{
                                    mt: 1
                                }}
                            >
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="admin-button"
                                    >
                                        Save changes
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </Grid>
                        {open && (
                            <EmailTemplateOnPreview
                                open={open}
                                setOpen={setOpen}
                                values={values}
                                content={content}
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

export default EditEmailTemplateForm;
