import { Button, CardContent, Grid, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import SolicitorList from './SolicitorList';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNewSolicitor from './add-new-solicitor';
import Tab from '@mui/material/Tab';

interface ListingPage {
    filterInput: string;
}

const SolicitorListing: React.FC<ListingPage | null> = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [filtersInput, setFiltersInput] = useState<string>(' ');
    const [displaySolciitorState, setSolicitorState] = useState<Boolean>(false);
    const [tabValue, setTabValue] = useState('STANDARD');

    const setPremiumOption = () => {
        setFiltersInput('PREMIUM');
    };

    const setStandardOption = () => {
        setFiltersInput('STANDARD');
    };

    const SolicitorDialog = () => {
        setSolicitorState(true);
        console.log(displaySolciitorState);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        setFiltersInput(newValue);
    };

    return (
        <MainCard content={false} className="no-border">
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="flex-end" spacing={8}>
                    <Grid item xs={8} sm={8}>
                        <Grid item>
                            <Typography variant="h3">Solicitors</Typography>
                        </Grid>
                    </Grid>
                    <Grid className="parent-solicitor-element">
                        <Grid item>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                                className="solicitor-tab"
                                aria-label="secondary tabs example"
                            >
                                <Tab label="Standard" value="STANDARD" />
                                <Tab label="Premium" value="PREMIUM" />
                            </Tabs>
                        </Grid>
                        <Grid>
                            <Button onClick={SolicitorDialog} variant="contained" className="info-media-button">
                                Invite New Solicitor
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {displaySolciitorState && <CreateNewSolicitor setSolicitorState={setSolicitorState} />}
            <SolicitorList filterInput={filtersInput || null} />
        </MainCard>
    );
};

export default SolicitorListing;
