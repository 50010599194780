import { Card, CardContent, FormControl, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import useScriptRef from 'hooks/useScriptRef';

const SolicitorCardDetails = ({ cardDetails }: { cardDetails: any }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    return (
        <>
            <Grid container>
                <Card variant="outlined" className="solicitor-details-card">
                    <CardContent>
                        <Typography variant="h6">Number Of Enquiries </Typography>
                        <Typography variant="h3" className="solicitor-card-value">
                            {cardDetails.numberOfEnquiries}{' '}
                        </Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" className="solicitor-details-card">
                    <CardContent>
                        <Typography variant="h6">Comprehensive Wills </Typography>
                        <Typography variant="h3" className="solicitor-card-value">
                            {cardDetails.numberOfComprehensiveWills}{' '}
                        </Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" className="solicitor-details-card">
                    <CardContent>
                        <Typography variant="h6">Total Solicitor Incomings </Typography>
                        <Typography variant="h3" className="solicitor-card-value">
                            ${cardDetails.solicitorIncomings}{' '}
                        </Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" className="solicitor-details-card">
                    <CardContent>
                        <Typography variant="h6">Sentiment Incomings </Typography>
                        <Typography variant="h3" className="solicitor-card-value">
                            ${cardDetails.sentimentIncomings}{' '}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default SolicitorCardDetails;
