import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/styles';
// material-ui
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports{ ...others }
// import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { StringColorProps } from 'types';
import useAuth from 'hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

// gql
import { useMutation } from '@apollo/client';
import { SETTINGS_CHANGE_PASSWORD } from 'queries/mutation';
import { InputChangePassword } from 'types/user';

interface LocationState {
    email: string;
    tempResetToken: string;
}

const AdminResetPassword = ({ ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();

    const [postSettingsChangePassword] = useMutation(SETTINGS_CHANGE_PASSWORD);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState<StringColorProps>();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPasswordNew = () => {
        setShowPasswordNew(!showPasswordNew);
    };

    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const handleMouseDownPassword = (event: React.SyntheticEvent) => {
        event.preventDefault();
    };

    const changePasswordField = (value: string) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const changePasswordSettings = async (values: InputChangePassword) => {
        const { oldPassword, newPassword } = values;
        const response = await postSettingsChangePassword({ variables: { body: values } });
        const { message } = response.data.adminChangePassword;
        navigate('/login', { state: message });
    };

    return (
        <Formik
            initialValues={{
                password: '',
                newPassword: '',
                confirmPassword: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().max(255).required('Password is required'),
                newPassword: Yup.string()
                    .max(255)
                    .required('Password is required')
                    .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                        'Password must be minimum 6 characters with at least one special character and at least one number'
                    ),

                confirmPassword: Yup.string().when('newPassword', {
                    is: (val: string) => !!(val && val.length > 0),
                    then: Yup.string().oneOf([Yup.ref('newPassword')], 'Both Password must be match!')
                })
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const changePasswordInput = { oldPassword: values.password, newPassword: values.newPassword };
                    await changePasswordSettings(changePasswordInput);
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err: any) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                    <Grid item xs={12} className="main-grid">
                        <Grid item xs={12} sm={6} lg={6} md={6}>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                {/* <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel> */}
                                <TextField
                                    className="settings-field"
                                    id="outlined-adornment-confirm-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    variant="filled"
                                    name="password"
                                    label="Current Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    FormHelperTextProps={{
                                        id: 'standard-weight-helper-text-password'
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.newPassword && errors.newPassword)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-newPassword-reset"
                                        type={showPasswordNew ? 'text' : 'password'}
                                        value={values.newPassword}
                                        name="newPassword"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="New Password"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changePasswordField(e.target.value);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordNew}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true
                                        }}
                                    />
                                </FormControl>
                                {touched.newPassword && errors.newPassword && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.newPassword}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                                {strength !== 0 && (
                                    <FormControl fullWidth>
                                        <Box sx={{ mb: 2 }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                    <Box
                                                        style={{ backgroundColor: level?.color }}
                                                        sx={{
                                                            width: 85,
                                                            height: 8,
                                                            borderRadius: '7px'
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" fontSize="0.75rem">
                                                        {level?.label}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-confirm-password"
                                        type={showPasswordConfirm ? 'text' : 'password'}
                                        value={values.confirmPassword}
                                        variant="filled"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordConfirm}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true
                                        }}
                                    />
                                </FormControl>

                                {touched.confirmPassword && errors.confirmPassword && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.confirmPassword}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>

                        {errors.submit && (
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                    </Grid>
                    <Grid className="flex-center-bottom">
                        <Box
                            sx={{
                                mt: 1
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

export default AdminResetPassword;
