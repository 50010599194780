import { useMutation } from '@apollo/client';
import { Button, ButtonBase, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { CREATE_ADMIN, INVITE_SOLICITOR } from 'queries/mutation';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Formik } from 'formik';
import { useTheme } from '@mui/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box } from '@mui/system';
import useScriptRef from 'hooks/useScriptRef';
import { useEffect } from 'react';

const CreateSolicitorForm = () => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const navigate = useNavigate();

    const [newSolicitorData, { data, error }] = useMutation(INVITE_SOLICITOR);

    const ApiCall = async (values: any) => {
        await newSolicitorData({
            variables: {
                body: {
                    fullName: values.solicitorName,
                    firmName: values.firmName,
                    email: values.email,
                    subscriptionType: values.subscriptionType
                }
            }
        });
    };

    return (
        <Formik
            initialValues={{
                solicitorName: '',
                firmName: '',
                email: '',
                subscriptionType: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                solicitorName: Yup.string()
                    .min(3, 'Solicitor Name must be atleast 3 characters')
                    .max(60, 'Solicitor Name must be at most 60 characters')
                    .trim()
                    .required('Solicitor Name is required'),
                firmName: Yup.string()
                    .min(3, 'Firm Name must be atleast 3 characters')
                    .max(60, 'Firm Name must be at most 60 characters')
                    .trim()
                    .required('Firm Name is required'),
                email: Yup.string()
                    .email('Must be a valid email')
                    .max(60, 'Email must be at most 60 characters')
                    .required('Email is required'),
                subscriptionType: Yup.string().required('Type is Required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const response = await ApiCall(values);

                    console.log('asdjasdjsdajd');
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    window.location.reload();
                } catch (err: any) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid item xs={12} className="invite-solicitor-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.solicitorName && errors.solicitorName)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-solicitorName-reset"
                                        value={values.solicitorName}
                                        name="solicitorName"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Solicitor Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </FormControl>
                                {touched.solicitorName && errors.solicitorName && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.solicitorName}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.firmName && errors.firmName)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-firmName-reset"
                                        value={values.firmName}
                                        name="firmName"
                                        variant="filled"
                                        InputProps={{ disableUnderline: true }}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Firm Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.firmName && errors.firmName && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.firmName}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.email && errors.email)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-email-reset"
                                        value={values.email}
                                        name="email"
                                        variant="filled"
                                        InputProps={{ disableUnderline: true }}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.email && errors.email && (
                                    <FormControl fullWidth sx={{ marginBottom: '8px' }}>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.email}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>

                            <Grid xs={12}>
                                <FormControl fullWidth error={Boolean(touched.subscriptionType && errors.subscriptionType)}>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        label="Type"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.subscriptionType}
                                        defaultValue={values.subscriptionType}
                                        name="subscriptionType"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="STANDARD">Standard</MenuItem>
                                        <MenuItem value="PREMIUM">Premium</MenuItem>
                                    </Select>
                                </FormControl>

                                {touched.subscriptionType && errors.subscriptionType && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.subscriptionType}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>

                        {errors.submit && (
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                    </Grid>

                    <AnimateButton>
                        <Button size="large" type="submit" variant="contained" className="solicitor-button">
                            Send Invite
                        </Button>
                    </AnimateButton>
                </form>
            )}
        </Formik>
    );
};

export default CreateSolicitorForm;
