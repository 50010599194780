import { NavItemType } from 'types';
import admin from './admin';
import clients from './clients';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [clients]
};

export default menuItems;
