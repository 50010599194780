const headCells = [
    {
        id: 'amount',
        numeric: true,
        label: 'Amount',
        align: 'left',
        type: 'amount'
    },
    {
        id: 'benefactor',
        numeric: true,
        label: 'Client',
        align: 'left',
        type: 'field'
    },
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date Created',
        align: 'left',
        type: 'Date'
    },
    {
        id: 'action',
        numeric: false,
        label: '',
        align: 'left',
        type: 'Enquiry'
    }
];

const actionTable = [
    {
        name: 'View Enquiry',
        variant: 'outlined',
        color: 'primary'
    }
];

const WillConstants = {
    headCells,
    actionTable
};

export default WillConstants;
