import { Button, FormControl, FormHelperText, Grid, MenuItem, Select, Snackbar, TextField } from '@mui/material';

import * as Yup from 'yup';
import 'yup-phone';

import { Formik } from 'formik';
import { useTheme } from '@mui/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box } from '@mui/system';
import useScriptRef from 'hooks/useScriptRef';

import SimpleSnackbar from 'components/common/SnackBar';

const EditAdminForm = ({ adminData, setAdminData, onUpdate }: { adminData: any; setAdminData: any; onUpdate: any }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    return (
        <Formik
            initialValues={{
                password: '',
                firstName: adminData?.firstName,
                lastName: adminData?.lastName,
                phoneNumber: adminData?.phoneNumber,
                email: adminData?.email,
                status: adminData?.status,
                submit: null
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .max(255)
                    .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                        'Password must be minimum 6 characters with at least one special character and at least one number'
                    ),
                firstName: Yup.string()
                    .min(3, 'First Name must be atleast 3 characters')
                    .max(60, 'First Name must be at most 60 characters')
                    .trim()
                    .required('First Name is required'),
                lastName: Yup.string()
                    .min(3, 'Last Name must be atleast 3 characters')
                    .max(60, 'Last Name must be at most 60 characters')
                    .trim()
                    .required('First Name is required'),
                email: Yup.string()
                    .email('Must be a valid email')
                    .max(60, 'Email must be at most 60 characters')
                    .required('Email is required'),
                phoneNumber: Yup.string()
                    .required('Phone Number is required')
                    .phone('AU', false, 'Phone Number must be valid')
                    .phone('NPL', false, 'Phone Number must be valid'),
                status: Yup.string().required('Status is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const response = await onUpdate(values);

                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err: any) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid item xs={12} className="main-grid">
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.firstName && errors.firstName)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="admin-text-field"
                                        id="outlined-adornment-firstName-reset"
                                        InputProps={{ disableUnderline: true }}
                                        value={values.firstName}
                                        name="firstName"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="First Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.firstName && errors.firstName && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.firstName}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.lastName && errors.lastName)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="admin-text-field"
                                        id="outlined-adornment-lastName-reset"
                                        InputProps={{ disableUnderline: true }}
                                        value={values.lastName}
                                        name="lastName"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Last Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.lastName && errors.lastName && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.lastName}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.email && errors.email)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="admin-text-field"
                                        id="outlined-adornment-email-reset"
                                        InputProps={{ disableUnderline: true }}
                                        value={values.email}
                                        name="email"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.email && errors.email && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.email}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="admin-text-field"
                                        id="outlined-adornment-phone-number"
                                        InputProps={{ disableUnderline: true }}
                                        value={values.phoneNumber}
                                        variant="filled"
                                        name="phoneNumber"
                                        label="Phone Number"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                    />
                                </FormControl>

                                {touched.phoneNumber && errors.phoneNumber && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.phoneNumber}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.status && errors.status)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <Select
                                        className="settings-select-field"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.status}
                                        name="status"
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="ACTIVE">Active</MenuItem>
                                        <MenuItem value="INACTIVE">Inactive</MenuItem>
                                    </Select>
                                </FormControl>

                                {touched.status && errors.status && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.status}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.password && errors.password)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="admin-text-field"
                                        id="outlined-adornment-confirm-password"
                                        value={values.password}
                                        variant="filled"
                                        name="password"
                                        label="Password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </FormControl>
                                {touched.password && errors.password && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.password}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>

                        {errors.submit && <SimpleSnackbar message={errors.submit} severity="error" />}
                    </Grid>
                    <Grid className="flex-center-bottom">
                        <Box
                            sx={{
                                mt: 1
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="admin-button"
                                >
                                    Save
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

export default EditAdminForm;
