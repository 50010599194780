import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import DanglingHelper from 'components/common/DanglingHelper';

import { LIST_ALL_EMAIL_TEMPLATE } from 'queries/queries';
import SimpleDataTable from 'components/common/SimpleDataTable';
import EmailTemplateListingConstants from './EmailTemplateListConstants';

const EmailTemplateList = ({ searchInput, sortInput }: { searchInput: any; sortInput: any }) => {
    const navigate = useNavigate();

    const [pageGet, setPageGet] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowData, setRowData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [onChangeSearch, setOnChangeSearch] = useState(false);

    const { data, error, loading, refetch } = useQuery(LIST_ALL_EMAIL_TEMPLATE, {
        variables: { query: { page: pageGet, perPage: pageSize, search: searchInput, sortBy: sortInput } }
    });

    const editEmailTemplate = (item: any) => navigate(`/email-template-list/${DanglingHelper(item, '_id')}/edit`);

    useEffect(() => {
        if (data) {
            setRowData(data?.listOfAllEmailTemplate?.data);
            setTotalCount(data?.listOfAllEmailTemplate?.pagination.total);
        }

        if (error) {
            setErrorMessage('No Data Found');
            setRowData([]);
            setTotalCount(0);
        }
        if (loading) {
            setErrorMessage('');
            setRowData([]);
            setTotalCount(0);
        }
    }, [error, loading]);

    const handleActionClickAction = (name: any, itemId: any, item: any) => {
        // switch (name) {
        //     case 'Edit Template':
        //         return console.log('No edit page');
        // }
        if (name === 'Edit Template') {
            editEmailTemplate(item);
        }
        return console.log('No edit page');
    };

    return (
        <>
            <SimpleDataTable
                error={errorMessage}
                loading={loading}
                getPageSize={setPageSize}
                getPageCount={setPageGet}
                rowData={rowData}
                handleClickAction={handleActionClickAction}
                // onPreviewRow={onPreviewRow}
                tableActions={EmailTemplateListingConstants.actionTable}
                headerCells={EmailTemplateListingConstants.headCells}
                totalItems={totalCount}
                changePage={onChangeSearch}
            />
        </>
    );
};

export default EmailTemplateList;
