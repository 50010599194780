import { useQuery } from '@apollo/client';
import Status from 'components/common/Status';
import SimpleDataTable from 'components/common/SimpleDataTable';

import { LIST_ALL_SHARED_PORTFOLIOS } from 'queries/queries';
import { useEffect, useState } from 'react';
import portfolioAcceptanceListConstants from './PortfolioAcceptanceListConstants';

const PortfolioList = ({ filterInput, searchInput }: { filterInput: string | null; searchInput: string | '' }) => {
    const [pageGet, setPageGet] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowData, setRowData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [onChangeSearch, setOnChangeSearch] = useState(false);

    const { data, error, loading, refetch } = useQuery(LIST_ALL_SHARED_PORTFOLIOS, {
        variables: {
            query: {
                page: pageGet,
                perPage: pageSize,
                search: searchInput,
                permissionApprovalStatus: filterInput === ' ' ? null : filterInput
            }
        }
    });

    useEffect(() => {
        if (data) {
            setRowData(data?.listAllSharedPortfolios?.data);
            setTotalCount(data?.listAllSharedPortfolios?.pagination.total);
        }

        if (error) {
            setErrorMessage('No Data Found');
            setRowData([]);
            setTotalCount(0);
        }
        if (loading) {
            setErrorMessage('');
            setRowData([]);
            setTotalCount(0);
        }
    }, [error, loading]);

    useEffect(() => {
        refetch();
        setOnChangeSearch(false);
    }, [refetch]);

    const handleActionClickAction = (name: any, itemId: any, item: any) => {
        switch (name) {
            case 'View Application':
                return console.log('No Application page');

            default:
                return console.log('{hello}');
        }
    };

    const onPreviewRow = (id: any, datas: any) => {
        console.log('id', id);
        console.log('data', datas);
    };

    return (
        <>
            <SimpleDataTable
                error={errorMessage}
                loading={loading}
                getPageSize={setPageSize}
                getPageCount={setPageGet}
                rowData={rowData}
                handleClickAction={handleActionClickAction}
                onPreviewRow={onPreviewRow}
                StatusComponent={Status}
                tableActions={portfolioAcceptanceListConstants.actionTable}
                headerCells={portfolioAcceptanceListConstants.headCells}
                totalItems={totalCount}
                changePage={onChangeSearch}
            />
        </>
    );
};

export default PortfolioList;
