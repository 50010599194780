import MoreVertIcon from '@mui/icons-material/MoreVert';

const headCells = [
    {
        id: 'beneficiaryFullName',
        numeric: false,
        label: 'Beneficiary Applying',
        align: 'left',
        type: 'beneficiaryFullNameField'
    },
    {
        id: 'benefactorFullName',
        numeric: false,
        label: 'Benefactor Account',
        align: 'left',
        type: 'benefactorFullNameField'
    },
    {
        id: 'appliedAt',
        numeric: true,
        label: 'Date Applied',
        align: 'left',
        type: 'Date'
    },

    {
        id: 'deathCertificate',
        label: 'Death Certificate',
        align: 'left',
        type: 'field'
    },
    {
        id: 'permissionApprovalStatus',
        label: 'Status',
        align: 'left',
        type: 'permissionApprovalStatus'
    },

    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'left',
        type: 'ActionApplication'
    }
];

const actionTable = [
    {
        name: 'View Application',
        variant: 'outlined',
        color: 'primary'
    }
];

const PortfolioAcceptanceListConstants = {
    headCells,
    actionTable
};

export default PortfolioAcceptanceListConstants;
