import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, REGISTER } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType, ServiceToken } from 'types/auth';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FORGOT_PASSWORD, VERIFY_FORGOT_PASSWORD_TOKEN, CHANGE_PASSWORD, CREATE_SOLICITOR, LOGIN_ADMIN } from 'queries/mutation';
import { AdminLoginData, InputUpdateResetPassword, InputVerifyResetPassword, RegisterApiData, SolicitorRegisterData } from 'types/user';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};
const setSession = (serviceToken: ServiceToken | null) => {
    if (serviceToken) {
        localStorage.setItem('accessToken', serviceToken?.accessToken);
        if (serviceToken?.refreshToken) {
            localStorage.setItem('refreshToken', serviceToken.refreshToken);
        }
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken.accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {},
    forgotPassword: () => Promise.resolve(),
    verifyforgotPasswordToken: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    register: () => Promise.resolve()
});

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [postLogin] = useMutation(LOGIN_ADMIN);
    const [postForgetPassword] = useMutation(FORGOT_PASSWORD);
    const [postVerifyForgetPasswordToken] = useMutation(VERIFY_FORGOT_PASSWORD_TOKEN);
    const [postChangePassword] = useMutation(CHANGE_PASSWORD);
    const [postRegister] = useMutation(CREATE_SOLICITOR);

    const { data } = useQuery(
        gql`
            query AuthenticatedUser {
                authenticatedUser {
                    _id
                    email
                    firstName
                    lastName
                    status
                    phoneNumber
                    role
                }
            }
        `
    );

    useEffect(() => {
        const init = async (dota: any) => {
            try {
                const serviceToken = window.localStorage.getItem('accessToken');
                if (!serviceToken) {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
                const user = dota ? dota.authenticatedUser : '';
                console.log(user, 'THIS IS FROM JWTCONTEXT');
                if (!verifyToken(String(serviceToken))) {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init(data);
    }, [data, navigate]);

    const login = async (email: AdminLoginData) => {
        const response = await postLogin({ variables: { body: email } });
        const { token, admin } = response.data.loginAdmin;
        const serviceToken: ServiceToken = token;
        setSession(serviceToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: admin
            }
        });
    };

    const forgotPassword = async (email: string) => {
        await postForgetPassword({ variables: { body: { email } } });
        navigate('/verification', { state: { email } });
    };
    const verifyforgotPasswordToken = async (values: InputVerifyResetPassword) => {
        const { email, resetToken: serviceToken } = values;
        const response = await postVerifyForgetPasswordToken({ variables: { body: values } });
        const { tempResetToken } = response.data.adminResetTokenVerification;
        if (tempResetToken) {
            navigate('/reset', { state: { email, tempResetToken } });
        }
    };
    const changePassword = async (values: InputUpdateResetPassword) => {
        const { email } = values;
        const response = await postChangePassword({ variables: { body: values } });
        const { message } = response.data.adminResetPasswordUpdate;
        navigate('/login', { state: { email, message } });
    };
    // todo: this flow need to be recode as it not verified
    const register = async (values: SolicitorRegisterData) => {
        const apiData: RegisterApiData = {
            fullName: values.fullName,
            firmName: values.firmName,
            email: values.email,
            firmContact: values.firmContact,
            subscriptionType: values.subscriptionType,
            password: values.password,
            firmAddress: values.firmAddress
        };
        const response = await postRegister({
            variables: {
                body: apiData
            }
        });
        const { solicitor, token } = response.data.createSolicitor;
        dispatch({
            type: REGISTER,
            payload: {
                isLoggedIn: false
            }
        });
        if (token) {
            navigate('/payment', { state: { token, solicitor } });
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, forgotPassword, verifyforgotPasswordToken, changePassword, register }}>
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
