import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';

import * as Yup from 'yup';

const SolicitorDetailsPage = ({ solicitor }: { solicitor: any }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }} className="solicitor-text-field">
                        <TextField
                            id="outlined-adornment-solicitorName-reset"
                            // type={showPasswordNew ? 'text' : 'password'}
                            value={solicitor.fullName}
                            name="solicitorName"
                            InputProps={{ disableUnderline: true }}
                            variant="filled"
                            FormHelperTextProps={{
                                id: 'standard-weight-helper-text-password'
                            }}
                            disabled
                            label="Solicitor Name"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }} className="solicitor-text-field">
                        <TextField
                            id="outlined-adornment-subscriptionType-reset"
                            // type={showPasswordNew ? 'text' : 'password'}
                            value={solicitor.subscriptionType}
                            name="subscriptionType"
                            InputProps={{ disableUnderline: true }}
                            variant="filled"
                            FormHelperTextProps={{
                                id: 'standard-weight-helper-text-password'
                            }}
                            disabled
                            label="Subscription "
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }} className="solicitor-text-field">
                        <TextField
                            id="outlined-adornment-email-reset"
                            value={solicitor.email}
                            name="email"
                            InputProps={{ disableUnderline: true }}
                            variant="filled"
                            FormHelperTextProps={{
                                id: 'standard-weight-helper-text-password'
                            }}
                            disabled
                            label="Email"
                        />
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} lg={6} md={6}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }} className="solicitor-text-field">
                        <TextField
                            id="outlined-adornment-confirm-password"
                            value={solicitor.contactNumber}
                            variant="filled"
                            name="phoneNumber"
                            InputProps={{ disableUnderline: true }}
                            label="Main Contact"
                            FormHelperTextProps={{
                                id: 'standard-weight-helper-text-password'
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SolicitorDetailsPage;
