import { useEffect, useState } from 'react';
import { Avatar, Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TablePagination from './PaginationTable';
import moment from 'moment';
import ActionButtonTable from './ActionButton';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import LinkTo from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';

const center = {
    textAlign: 'center',
    direction: 'row',
    justifyContent: 'center'
};

interface AdminRolesInterface {
    ADMIN: string;
    SUPER_ADMIN: string;
}

const SimpleDataTable = ({
    headerCells = [],
    rowData = [],
    tableActions,
    handleClickAction,
    totalItems = 0,
    getPageSize,
    getPageCount,
    onPreviewRow,
    StatusComponent,
    changePage
}: any) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const AdminRoles: any = {
        ADMIN: 'Admin',
        SUPER_ADMIN: 'Super Admin'
    };
    const Status: any = {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        DISABLED: 'Disabled'
    };
    const permissionApprovalStatus: any = {
        PENDING: 'Pending',
        REJECTED: 'Rejected',
        APPROVED: 'Approved'
    };

    const { user } = useAuth();

    useEffect(() => {
        if (changePage) setPage(0);
    }, [changePage]);
    const handlePageChange = (pages: any) => {
        setPage(pages);
        if (getPageCount) getPageCount(pages);
    };
    const handlePageSizeChange = (size: any) => {
        setPageSize(size);
        if (getPageSize) getPageSize(size);
    };
    const getItemId = (row: any, key: any) => row[key];
    const getValueForDeepObject = (row: any, id: any) => {
        const mainId = id;
        let rowValue = row;

        if (mainId.includes('.')) {
            const splittedId = mainId.split('.');
            splittedId.map((splitId: any) => {
                row = row[splitId];
                return splitId;
            });
        } else rowValue = rowValue[id];

        return rowValue;
    };

    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEmailEditButton = (row: any) => {
        handleClickAction('Edit Template', getItemId(row, '_id'), row);
    };

    return (
        <>
            <TableContainer
                sx={{
                    boxSizing: 'border-box',
                    display: 'block',
                    maxWidth: 'calc(100vw - 90px)'
                }}
            >
                <Table sx={{ overflow: 'hidden' }}>
                    <TableHead className="border-top-head">
                        <TableRow className="header-cells">
                            {headerCells.map((header: any, index: number) => (
                                <TableCell align={header.align} width={header?.width} className={header?.className}>
                                    {header.hide ? '' : <Typography variant="body1">{header.label}</Typography>}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData &&
                            rowData.map((row: any, index: number) => (
                                <TableRow onClick={onPreviewRow}>
                                    {headerCells.map((header: any) => {
                                        const { id, align, type } = header;
                                        let sn = 0;
                                        if (id === 'id') {
                                            if (page === 0) sn = page * pageSize + index + 1;
                                            else sn = (page - 1) * pageSize + index + 1;
                                        }
                                        return (
                                            <>
                                                {type === 'field' && (
                                                    <TableCell align={align}>
                                                        {id === 'id' ? (
                                                            sn
                                                        ) : (
                                                            <Typography variant="body1" color="#262626">
                                                                {getValueForDeepObject(row, id) || ' N/A '}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                )}
                                                {type === 'silicitorName' && (
                                                    <TableCell align={align}>
                                                        {id === 'id' ? (
                                                            sn
                                                        ) : (
                                                            <LinkTo
                                                                href={`/clients/solicitors/${getValueForDeepObject(row, '_id')}`}
                                                                underline="none"
                                                            >
                                                                <Typography variant="body1" color="#2F80ED">
                                                                    {getValueForDeepObject(row, id) || ' N/A '}
                                                                </Typography>
                                                            </LinkTo>
                                                        )}
                                                    </TableCell>
                                                )}
                                                {type === 'amount' && (
                                                    <TableCell align={align}>
                                                        {id === 'id' ? (
                                                            sn
                                                        ) : (
                                                            <Typography variant="body1" color="#262626">
                                                                ${getValueForDeepObject(row, id) || ' N/A '}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                )}
                                                {type === 'location' && (
                                                    <TableCell align={align}>
                                                        {id === 'id' ? (
                                                            sn
                                                        ) : (
                                                            <Typography variant="body1" color="#262626">
                                                                {(getValueForDeepObject(row, id) &&
                                                                    getValueForDeepObject(row, id).displayAddress) ||
                                                                    ' N/A '}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                )}

                                                {type === 'avatar' && (
                                                    <TableCell align={align}>
                                                        <Avatar />
                                                    </TableCell>
                                                )}
                                                {type === 'Enquiry' && (
                                                    <TableCell align={align}>
                                                        <Link to="#" style={{ textDecoration: 'none' }}>
                                                            {tableActions[0].name}
                                                        </Link>
                                                    </TableCell>
                                                )}
                                                {type === 'name' && (
                                                    <TableCell align={align} className="admin_name_cell">
                                                        <Avatar />
                                                        <Typography variant="body1" color="#262626">
                                                            {getValueForDeepObject(row, id.split(',')[0]) || ' N/A '}{' '}
                                                            {getValueForDeepObject(row, id.split(',')[1]) || ' N/A '}
                                                        </Typography>
                                                    </TableCell>
                                                )}
                                                {type === 'Action' && row.role === 'ADMIN' && (
                                                    <TableCell align={align}>
                                                        {user?.role === 'SUPER_ADMIN' && (
                                                            <ActionButtonTable
                                                                status={row.status}
                                                                newOptions={tableActions}
                                                                handleClickItem={handleClickAction}
                                                                item={row}
                                                                itemId={() => getItemId(row, '_id')}
                                                            />
                                                        )}
                                                    </TableCell>
                                                )}
                                                {type === 'ActionSolicitor' && (
                                                    <TableCell align={align}>
                                                        <ActionButtonTable
                                                            status={row.status}
                                                            newOptions={tableActions}
                                                            handleClickItem={handleClickAction}
                                                            item={row}
                                                            itemId={() => getItemId(row, '_id')}
                                                        />
                                                    </TableCell>
                                                )}

                                                {type === 'ActionEmailTemplate' && (
                                                    <TableCell align={align}>
                                                        <Grid container spacing={2} sx={{ left: '60px' }}>
                                                            <Box
                                                                sx={{
                                                                    border: 1,
                                                                    borderColor: '#2563EB',
                                                                    borderRadius: 1,
                                                                    marginTop: 2,
                                                                    padding: '5px 0px '
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={(e) => handleEmailEditButton(row)}
                                                                    className="action-button"
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </TableCell>
                                                )}
                                                {type === 'role' && (
                                                    <TableCell align={align}>
                                                        <Typography variant="body1" color="#262626">
                                                            {AdminRoles[getValueForDeepObject(row, id)] || ' N/A '}
                                                        </Typography>
                                                    </TableCell>
                                                )}
                                                {type === 'Status' && (
                                                    <TableCell align={align}>
                                                        {' '}
                                                        <StatusComponent status={Status[getValueForDeepObject(row, id) || '']} />
                                                    </TableCell>
                                                )}
                                                {type === 'Date' && (
                                                    <TableCell align={align}>
                                                        {' '}
                                                        <>
                                                            {getValueForDeepObject(row, id) ? (
                                                                <Typography color="black">
                                                                    {' '}
                                                                    {moment(getValueForDeepObject(row, id)).format('DD/MM/YY')}
                                                                </Typography>
                                                            ) : (
                                                                <Typography>N/A</Typography>
                                                            )}
                                                        </>
                                                    </TableCell>
                                                )}
                                                {type === 'beneficiaryFullNameField' && (
                                                    <TableCell align={align}>
                                                        {id === 'id' ? (
                                                            sn
                                                        ) : (
                                                            <Typography variant="body1" color="#2F80ED">
                                                                {getValueForDeepObject(row, id) || ' N/A '}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                )}
                                                {type === 'benefactorFullNameField' && (
                                                    <TableCell align={align}>
                                                        {id === 'id' ? (
                                                            sn
                                                        ) : (
                                                            <Typography variant="body1" color="#2F80ED">
                                                                {getValueForDeepObject(row, id) || ' N/A '}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                )}
                                                {type === 'permissionApprovalStatus' && (
                                                    <TableCell align={align}>
                                                        {' '}
                                                        {console.log(
                                                            'statuss permission=>',
                                                            getValueForDeepObject(row, id) == 'REQUESTED'
                                                                ? 'PENDING'
                                                                : getValueForDeepObject(row, id)
                                                        )}
                                                        <StatusComponent
                                                            status={
                                                                permissionApprovalStatus[
                                                                    getValueForDeepObject(row, id) == 'REQUESTED'
                                                                        ? 'PENDING'
                                                                        : getValueForDeepObject(row, id)
                                                                ]
                                                            }
                                                        />
                                                    </TableCell>
                                                )}
                                                {type === 'ActionApplication' && (
                                                    <TableCell align={align}>
                                                        {row.permissionApprovalStatus !== 'APPROVED' && (
                                                            <ActionButtonTable
                                                                status={row.permissionApprovalStatus}
                                                                newOptions={tableActions}
                                                                handleClickItem={handleClickAction}
                                                                item={row}
                                                                itemId={() => getItemId(row, '_id')}
                                                            />
                                                        )}

                                                        {console.log('status=?', row.permissionApprovalStatus)}
                                                    </TableCell>
                                                )}
                                            </>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {rowData.length ? (
                <TablePagination
                    handleChangePageSize={handlePageSizeChange}
                    count={totalItems}
                    pageSize={pageSize}
                    pageNumber={page}
                    handlePaginationChange={handlePageChange}
                />
            ) : null}
        </>
    );
};

export default SimpleDataTable;
