import { useMutation } from '@apollo/client';
import { Button, FormControl, FormHelperText, Grid, MenuItem, Select, TextField } from '@mui/material';
import { CREATE_ADMIN } from 'queries/mutation';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import 'yup-phone';

import { Formik } from 'formik';
import { useTheme } from '@mui/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box } from '@mui/system';
import useScriptRef from 'hooks/useScriptRef';
import { useEffect } from 'react';

const CreateAdminForm = () => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const navigate = useNavigate();

    const [newAdminData, { data }] = useMutation(CREATE_ADMIN);

    const ApiCall = async (values: any) => {
        await newAdminData({
            variables: {
                body: {
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    email: values.email,
                    status: values.status
                }
            }
        });
    };

    useEffect(() => {
        if (data) {
            navigate('/admins');
        }
    }, [data]);

    return (
        <Formik
            initialValues={{
                password: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                status: 'INACTIVE',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .max(255)
                    .required('Password is required')
                    .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                        'Password must be minimum 6 characters with at least one special character and at least one number'
                    ),
                firstName: Yup.string()
                    .min(3, 'First name must be atleast 3 characters')
                    .max(60, 'First name must be at most 60 characters')
                    .trim()
                    .required('First name is required'),
                lastName: Yup.string()
                    .min(3, 'Last name must be atleast 3 characters')
                    .max(60, 'Last name must be at most 60 characters')
                    .trim()
                    .required('Last name is required'),
                email: Yup.string()
                    .email('Must be a valid email')
                    .max(60, 'Email must be at most 60 characters')
                    .required('Email is required'),
                phoneNumber: Yup.string()
                    .required('Phone Number is required')
                    .phone('AU', false, 'Phone Number must be valid')
                    .phone('NPL', false, 'Phone Number must be valid'),
                status: Yup.string().required('Status is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const response = await ApiCall(values);
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err: any) {
                    console.error(err);
                    console.log(err.extensions.response.message);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.extensions.response.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid item xs={12} className="main-grid">
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.firstName && errors.firstName)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="admin-text-field"
                                        id="outlined-adornment-firstName-reset"
                                        value={values.firstName}
                                        name="firstName"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                        label="First Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.firstName && errors.firstName && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.firstName}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.lastName && errors.lastName)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-lastName-reset"
                                        value={values.lastName}
                                        name="lastName"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                        label="Last Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.lastName && errors.lastName && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.lastName}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.email && errors.email)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-email-reset"
                                        value={values.email}
                                        name="email"
                                        variant="filled"
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                {touched.email && errors.email && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-reset">
                                            {errors.email}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-confirm-password"
                                        value={values.phoneNumber}
                                        variant="filled"
                                        name="phoneNumber"
                                        label="Phone Number"
                                        onBlur={handleBlur}
                                        InputProps={{ disableUnderline: true }}
                                        onChange={handleChange}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                    />
                                </FormControl>

                                {touched.phoneNumber && errors.phoneNumber && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.phoneNumber}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.status && errors.status)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <Select
                                        className="settings-select-field"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.status}
                                        defaultValue={values.status}
                                        name="status"
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="ACTIVE">Active</MenuItem>
                                        <MenuItem value="INACTIVE">Inactive</MenuItem>
                                    </Select>
                                </FormControl>

                                {touched.status && errors.status && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.status}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.password && errors.password)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <TextField
                                        className="settings-field"
                                        id="outlined-adornment-confirm-password"
                                        value={values.password}
                                        variant="filled"
                                        name="password"
                                        label="Password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        FormHelperTextProps={{
                                            id: 'standard-weight-helper-text-password'
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </FormControl>
                                {touched.password && errors.password && (
                                    <FormControl fullWidth>
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {' '}
                                            {errors.password}{' '}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>

                        {errors.submit && (
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                    </Grid>
                    <Grid className="flex-center-bottom">
                        <Box
                            sx={{
                                mt: 1
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="admin-button"
                                >
                                    Create
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

export default CreateAdminForm;
