// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider } from 'contexts/JWTContext';

// ==============================|| APP ||============================== //

// const client: ApolloClient<any>  = null;

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        {/* <RTLLayout> */}
        <Locales>
            <NavigationScroll>
                {/* <ApolloProvider client={client}> */}
                <JWTProvider>
                    <>
                        <Routes />
                        <Snackbar />
                    </>
                </JWTProvider>
                {/* </ApolloProvider> */}
            </NavigationScroll>
        </Locales>
        {/* </RTLLayout> */}
    </ThemeCustomization>
);

export default App;
