import MoreVertIcon from '@mui/icons-material/MoreVert';

const headCells = [
    {
        id: 'firstName,lastName',
        numeric: false,
        label: 'Name',
        align: 'left',
        type: 'name',
        className: 'admin-name'
    },
    {
        id: 'phoneNumber',
        numeric: true,
        label: 'Phone',
        align: 'left',
        type: 'field'
    },
    {
        id: 'email',
        numeric: true,
        label: 'Email',
        align: 'left',
        type: 'field'
    },
    {
        id: 'role',
        numeric: true,
        label: 'Role',
        align: 'left',
        type: 'role'
    },
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date Created',
        align: 'left',
        type: 'Date'
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left',
        type: 'Status'
    },

    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'left',
        type: 'Action'
    }
];

const actionTable = [
    {
        name: 'Delete Admin',
        variant: 'outlined',
        color: 'primary'
    },
    {
        name: 'Edit Admin',
        variant: 'outlined',
        color: 'primary'
    }
];

const AdminListingConstants = {
    headCells,
    actionTable
};

export default AdminListingConstants;
