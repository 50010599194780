import { useMutation, useQuery } from '@apollo/client';
import Status from 'components/common/Status';
import SimpleDataTable from 'components/common/SimpleDataTable';
import { DELETE_SOLICITOR } from 'queries/mutation';
import { LIST_ALL_SOLICITORS } from 'queries/queries';
import { useEffect, useState } from 'react';
import SolicitorListingConstants from './SolicitorListConstants';
import DanglingHelper from 'components/common/DanglingHelper';
import DisableModal from 'components/common/DeleteComponent';

const SolicitorList = ({ filterInput }: { filterInput: string | null }) => {
    const [pageGet, setPageGet] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowData, setRowData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const [selectedItem, setSelectedItem] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { data, error, loading, refetch } = useQuery(LIST_ALL_SOLICITORS, {
        variables: { query: { page: pageGet, perPage: pageSize, subscriptionType: filterInput === ' ' ? 'STANDARD' : filterInput } }
    });

    console.log('datas=>', data);
    const [deleteAdminData] = useMutation(DELETE_SOLICITOR);

    const onShowOrHideDisableModal = (flag: any) => {
        if (!flag) {
            setSelectedItem(null);
        }
        setShowDeleteModal(flag);
    };

    const deleteRow = (item: any) => {
        setSelectedItem(item);
        onShowOrHideDisableModal(true);
    };

    useEffect(() => {
        if (data) {
            setRowData(data?.listOfAllSolicitors?.data);
            setTotalCount(data?.listOfAllSolicitors?.pagination.total);
            setDeleteErrorMessage('');
        }

        if (error) {
            setDeleteErrorMessage('No Data Found');
            setErrorMessage('No Data Found');
            setRowData([]);
            setTotalCount(0);
        }
        if (loading) {
            setErrorMessage('');
            setRowData([]);
            setTotalCount(0);
        }
    }, [error, loading]);

    const onDeleteApiCall = async () => {
        try {
            await deleteAdminData({
                variables: { id: DanglingHelper(selectedItem, '_id') || '' }
            });
            window.location.reload();

            onShowOrHideDisableModal(false);
        } catch (e) {
            setErrorMessage('Solicitor cannot be deleted');
            onShowOrHideDisableModal(false);
        }
    };

    useEffect(() => {
        refetch();
        setOnChangeSearch(false);
    }, [refetch]);

    const handleActionClickAction = (name: any, itemId: any, item: any) => {
        switch (name) {
            case 'Edit Solicitor':
                return console.log('No edit page');
            case 'Delete Solicitor':
                return deleteRow(item);
            default:
                return console.log('{hello}');
        }
    };

    const onPreviewRow = (id: any, datas: any) => {
        console.log('id', id);
        console.log('data', datas);
    };

    return (
        <>
            <SimpleDataTable
                error={errorMessage}
                loading={loading}
                getPageSize={setPageSize}
                getPageCount={setPageGet}
                rowData={rowData}
                handleClickAction={handleActionClickAction}
                onPreviewRow={onPreviewRow}
                StatusComponent={Status}
                tableActions={SolicitorListingConstants.actionTable}
                headerCells={SolicitorListingConstants.headCells}
                totalItems={totalCount}
                changePage={onChangeSearch}
            />
            <DisableModal
                onShowOrHideDisableModal={onShowOrHideDisableModal}
                content="Are you sure you want to delete Solicitor?"
                onOkClicked={onDeleteApiCall}
                open={showDeleteModal}
                title="Delete Solicitor"
            />
        </>
    );
};

export default SolicitorList;
